const ATTRIBUTE = 'style'
const Z_INDEX = '11'
const POSITION = 'relative'

class OverlayHelper {
  constructor () {
    if (OverlayHelper.Singleton) return

    OverlayHelper.Singleton = this
  }

  /**
   * @param {*} element
   * @param {boolean} changeScrollBehaviour
   */
  async highlight (element = null, changeScrollBehaviour = false) {
    await _setVisibility(!!element)
    const { DomHelper } = await _getModules()

    if (this.element) {
      this.element.removeAttribute(ATTRIBUTE)
      changeScrollBehaviour && DomHelper.enableBodyScroll()
    }
    if (element) {
      element.style.zIndex = Z_INDEX
      element.style.position = POSITION
      changeScrollBehaviour && DomHelper.disableBodyScroll()
    }
    this.element = element
  }
}

/**
 * @param {HTMLElement|boolean} isVisible
 */
const _setVisibility = async (isVisible) => {
  const { overlay } = await _getModules()

  await overlay.changeVisibility({ isVisible })
}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [ { default: DomHelper }, { useOverlayStore } ] = await Promise.all([
      await import('@/services/helpers/DomHelper'),
      await import('@/stores/overlay'),
    ])
    cache = { DomHelper, overlay: useOverlayStore() }
    return cache
  }
})()

export default new OverlayHelper()
