import EnvironmentHelper from '@/services/helpers/EnvironmentHelper'
import { i18n } from '@/plugins/i18n'

export const CONFIG_MAP = {
  de: {
    name: 'Vicampo.de',
    domain: 'www.vicampo.de',
    siteId: 10000,
    homePageId: 451,
    lang: 'de-DE',
    locale: 'de',
    currency: 'EUR',
    gaTrackingId: 'UA-18996861-1',
    phone: {
      text: '06131 / 30 29 30',
      link: '+496131302930',
    },
    email: 'kundenservice@vicampo.de',
    trustedSiteLogo: 'trusted-shops-de.svg',
    trustedSiteId: 'XF71F1C9F09BC4CCB4B222702CEEF5AC2',
    defaultShippingCountry: 'de',
  },
  at: {
    name: 'Vicampo.at',
    domain: 'www.vicampo.at',
    siteId: 10001,
    homePageId: 1543,
    lang: 'de-AT',
    locale: 'at',
    currency: 'EUR',
    gaTrackingId: 'UA-18996861-5',
    phone: {
      text: '+43 720 / 81 64 69',
      link: '+43720816469',
    },
    email: 'kundenservice@vicampo.at',
    trustedSiteLogo: 'trusted-shops-at.svg',
    trustedSiteId: null,
    defaultShippingCountry: 'at',
  },
  ch: {
    name: 'Vicampo.ch',
    domain: 'www.vicampo.ch',
    siteId: 10005,
    homePageId: 1769,
    lang: 'de-CH',
    locale: 'ch',
    currency: 'CHF',
    gaTrackingId: 'UA-18996861-8',
    phone: {
      text: '+41 44 55 102 60',
      link: '+41445510260',
    },
    email: 'kundenservice@vicampo.ch',
    trustedSiteLogo: 'trusted-shops-ch.svg',
    trustedSiteId: null,
    defaultShippingCountry: 'ch',
  }
}

const APP_BANNER_PAGES = [CONFIG_MAP.de.siteId, CONFIG_MAP.ch.siteId, CONFIG_MAP.at.siteId]

/**
 * @class
 * @name SiteConfig
 *
 * @property {Number} siteId
 * @property {String} locale
 * @property {String} lang
 * @property {Object} currencyConfig
 * @property {String} gaTrackingId
 * @property {String} name
 * @property {String} domain
 * @property {String} apiDomain
 * @property {Boolean} appBanner
 * @property {String} appleItunesAppId
 * @property {Object} phone
 *
 */

class SiteConfig {
  constructor () {
    if (SiteConfig.Singleton) return

    SiteConfig.Singleton = this
    this.devMode = (process.env.VUE_APP_DEV_MODE === 'ON')
    this.init(CONFIG_MAP[EnvironmentHelper.detectTopLevelDomain()])
  }

  async offerOtherSite () {
    const [
      { useCustomerStore },
      { default: CookieHelper }
    ] = await Promise.all([
      import('@/stores/customer'),
      import('@/services/helpers/CookieHelper'),
    ])
    const { language } = navigator
    const customerStore = useCustomerStore()

    if (
      language === this.lang
      || customerStore.customerIsAdmin
      || CookieHelper.getCookie('vic_country_switcher')
    ) return null

    const offeredSite = _checkForSite(this.name, language, 'lang')
      || _checkForSite(this.name, language, 'locale')

    return offeredSite ? { ...offeredSite, currentDomain: this.domain, currentSiteId: this.siteId } : null
  }

  /**
   *
   * @param {Object} map
   */
  init (map) {
    this.siteId = map.siteId
    this.homePageId = map.homePageId
    this.locale = map.locale
    this.lang = map.lang
    this.name = map.name
    this.currencyConfig = _getCurrencyConfig(map.currency)
    this.gaTrackingId = map.gaTrackingId
    this.phone = map.phone
    this.email = map.email
    const domain = EnvironmentHelper.detectDomain()
    this.domain = domain
    this.pageRoot = `https://${domain}/`
    this.apiRoot = `https://${EnvironmentHelper.detectApiDomain(domain)}/`
    this.appBanner = APP_BANNER_PAGES.includes(map.siteId)
    this.appleItunesAppId = 'app-id=1269550379'
    this.trustedSiteId = map.trustedSiteId
    this.trustedSiteLogo = map.trustedSiteLogo
    this.defaultShippingCountry = map.defaultShippingCountry
    void _setupTranslations(this.lang)
  }
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
 *
 * @param {String} currency
 * @return {{style: String, currency: String}}
 */
const _getCurrencyConfig = currency => ({ style: 'currency', currency: currency })

const _checkForSite = (name, search, referenceField) => Object.values(CONFIG_MAP)
  .filter(site => name !== site.name && site[referenceField] === search)[0]

const _setupTranslations = async (lang) => {
  const { default: messages } = await import(`@/assets/translations/${lang}.json`)
  i18n.setLocaleMessage(lang, messages)
  i18n.locale = lang
}

export default new SiteConfig()
