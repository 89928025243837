import { HTML_CLASS } from '@/constants/GlobalConstants'

class DomHelper {
  /**
   * @param {String|Element} element
   * @param {Number} offset
   * @param {Number} delay
   */
  static async scrollIntoView (element, offset = 0, delay = 0) {
    await new Promise(resolve => { setTimeout(resolve, delay) })
    void _scrollTo(element, offset)
  }

  /**
   * @param {Element} element
   * @param {Number} offset
   * @return {Boolean}
   */
  static elementInViewport (element, offset = 0) {
    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const { top, bottom } = element.getBoundingClientRect()

    // top is in the viewport
    if (top > offset && top < windowHeight) return true

    // top is above the viewport but bottom is in or below
    return top < offset && bottom > offset
  }

  /**
   * Enable scroll
   */
  static enableBodyScroll () {
    document.body.classList.remove(HTML_CLASS.NO_SCROLL)
  }

  /**
   * Disable scroll
   */
  static disableBodyScroll () {
    document.body.classList.add(HTML_CLASS.NO_SCROLL)
  }

  /**
   * @param {String} selector
   * @param {Number} offset
   */
  static scrollTo (selector, offset = 0) {
    if (offset) {
      const elementPosition = document.querySelector(selector).getBoundingClientRect().top
      const offsetPosition = elementPosition + window.scrollY - offset
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      })
    } else {
      document.querySelector(selector).scrollIntoView({
        behavior: 'smooth'
      })
    }
  }
}

const _scrollTo = async (element, offset) => {
  const elem = typeof element === 'string' ? document.getElementById(element) : element

  if (!elem) return
  const { SmoothScroll } = await _getModules()
  new SmoothScroll('a[href*="#"]', { updateURL: false, offset })
    .animateScroll(elem)
}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [
      { default: SmoothScroll }
    ] = await Promise.all([
      await import('smooth-scroll')
    ])
    cache = { SmoothScroll }
    return cache
  }
})()

export default DomHelper
