const PRIVATE_PARAMS = ['ph', 'email', 'coupon']
const LINK_TAGS = ['canonical', 'alternate']

export const createMetaObject = async ({ title, ...rest }) => {
  const { SiteConfig } = await _getModules()

  const prefix = SiteConfig.devMode ? '[ 👾] ' : ''
  const titleTemplate = `${prefix}%s | ${SiteConfig.name}`
  const pagePrivate = await _checkPrivateInfo()

  const { meta, link } = mapMetaData(rest, pagePrivate)

  return { titleTemplate, title, meta, link }
}

const _getModules = (() => {
  let cache

  return async () => {
    if (cache) return cache

    const [{ default: SiteConfig }, { default: router }] = await Promise.all([
      await import('@/services/SiteConfig'),
      await import('@/router'),
    ])
    cache = {
      SiteConfig,
      router,
    }
    return cache
  }
})()

const _checkPrivateInfo = async () => {
  const {
    router: {
      currentRoute: { query = {} },
    },
  } = await _getModules()

  return PRIVATE_PARAMS.find((p) => Object.hasOwn(query, p)) !== undefined
}

const mapMetaData = (data, noIndex) => {
  // [ { rel: 'something', random_prop: '123' } ]
  const link = Object.keys(data)
    .filter((key) => LINK_TAGS.includes(key))
    .reduce(
      (tags, key) => [
        ...tags,
        ...data[key].map((content) => ({ rel: key, ...content })),
      ],
      [],
    )

  // [ { vmid: '', name: '', content: '' } ]
  const meta = Object.keys(data)
    .filter((key) => !LINK_TAGS.includes(key))
    .reduce((tags, key) => {
      if (!data[key]) {
        return tags
      }
      if (key === 'robots' && noIndex) {
        return [
          ...tags,
          { vmid: key, name: key, content: 'noindex, nofollow' },
        ]
      }
      return [...tags, { vmid: key, name: key, content: data[key] }]
    }, [])

  return { meta, link }
}
