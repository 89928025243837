import FontFaceObserver from 'fontfaceobserver'

(function loadFonts () {
  const observers = []
  const fontFamilies = {
    'Lato': [
      { weight: 300, style: 'italic' },
      { weight: 400, style: 'italic' },
      { weight: 700, style: 'italic' },
      { weight: 900, style: 'italic' },
      { weight: 300, style: 'normal' },
      { weight: 400, style: 'normal' },
      { weight: 700, style: 'normal' },
      { weight: 900, style: 'normal' }
    ],
    'Libre Baskerville': [
      { weight: 400, style: 'italic' },
      { weight: 400, style: 'normal' },
      { weight: 700, style: 'normal' }
    ]
  }
  Object.keys(fontFamilies).forEach(font => {
    observers.push(new FontFaceObserver(font, fontFamilies[font]).load(null, 5000))
  })
  Promise.all(observers).catch((err) => {
    console.warn('Some critical font are not available:', err)
  })
}())
